import { ReactElement, createRef, useContext, useRef } from "react";
import { ConfirmDialog, ConfirmDialogProps } from "./ConfirmDialog";
import { DialogContext } from "./DialogContext";

type DialogProps<TResult extends boolean = boolean> = {
  onClose: (value: TResult) => void;
  dialogRef: React.MutableRefObject<HTMLDialogElement | null>;
};
export const useDialog = () => {
  const { setDialog } = useContext(DialogContext);
  const onCloseParamsRef = useRef<boolean>();
  const openDialog = <
    TResult extends boolean = boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TAdditionalProps extends Record<string, any> | undefined = undefined
  >(
    component: (props: DialogProps<TResult> & TAdditionalProps) => ReactElement,
    additionalProps?: TAdditionalProps
  ) =>
    new Promise<TResult | undefined>((resolve) => {
      const dialogRef = createRef<HTMLDialogElement>();
      setTimeout(() => {
        dialogRef.current?.addEventListener(
          "close",
          () => {
            const prevParamValue = onCloseParamsRef.current ?? false;
            onCloseParamsRef.current = undefined;
            return resolve(prevParamValue as TResult);
          },
          { once: true }
        );
        dialogRef.current?.showModal();
        // On met 10ms de délai car dans le cas ou on appelle openDialog dans autre chose que dans un onclick d'un bouton alors la ref est null
      }, 10);
      setDialog({
        component,
        props: {
          ...additionalProps,
          onClose: (value: TResult) => {
            onCloseParamsRef.current = value;
            dialogRef.current?.close();
            setTimeout(() => setDialog(null), 0);
          },
          dialogRef,
        },
      });
    });
  return {
    openDialog,
    openConfirmDialog: async (
      props: Omit<ConfirmDialogProps, "onClose" | "dialogRef">
    ): Promise<boolean> => {
      const result = await openDialog(({ onClose, dialogRef }) =>
        ConfirmDialog({
          ...props,
          onClose,
          dialogRef,
        })
      );
      return result ?? false;
    },
  };
};

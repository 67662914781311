import { GatewayContact } from "@conformite/gateway";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { Table } from "@src/components/Table/Table";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useMe } from "@src/store/store.me";
import { ReactNode } from "react";
import style from "./GestionUtilisateurByRole.module.scss";

type UtilisateurRoleType = {
  nom: string;
  entreprises: string;
  role: string;
  id: string;
};

type GestionUtilisateursByRole = {
  title: string;
  actions?: ReactNode;
  info: ReactNode;
  data: UtilisateurRoleType[];
  isLoading: boolean;
  onDelete: (id: string) => void;
  onUpdate: (id: string) => void;
};

export function GestionUtilisateursByRole({
  title,
  info,
  actions,
  data,
  isLoading,
  onDelete,
  onUpdate,
}: GestionUtilisateursByRole) {
  const { me } = useMe();
  const { hasAllowedRole } = useIsAllowed();

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <h2>{title}</h2>
        {actions}
      </div>

      <InfoCard className={style.infoCard}>{info}</InfoCard>
      {isLoading && <SkeletonCard />}
      {!isLoading && (
        <Table<UtilisateurRoleType>
          columns={[
            {
              key: "name",
              title: "Prénom Nom",
              size: 1,
              render: ({ nom }) => <p>{nom}</p>,
            },
            {
              key: "company",
              title: "Société",
              size: 1,
              className: style.cellOneLineItem,
              render: ({ entreprises }) => (
                <p title={entreprises} className={style.oneLineItem}>
                  {entreprises}
                </p>
              ),
            },
            {
              key: "role",
              title: "Rôle",
              size: 1,
              className: style.oneLineItem,
              render: ({ role }) => <p title={role}>{role}</p>,
            },
            {
              key: "actions",
              size: "auto",
              render: (contact) => {
                if (
                  !hasAllowedRole(
                    GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR
                  ) ||
                  me?.contact.id === contact.id
                )
                  return <div />;
                return (
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(contact.id);
                      }}
                      variant="text"
                      iconVariant="left"
                      icon={<TrashIcon />}
                    />
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onUpdate(contact.id);
                      }}
                      variant="text"
                      iconVariant="left"
                      icon={<Pencil />}
                    />
                  </>
                );
              },
            },
          ]}
          data={data}
        />
      )}
    </div>
  );
}

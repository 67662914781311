import { GatewayAuthentication } from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { downloadFile, getDownloadedFile } from "@src/helper/file.helper";
import { useIsAllowed } from "@src/session/useIsAllowed";
import styles from "./DownloadFile.module.scss";

type DownloadFileProps = {
  siren: string;
  millesime: number;
  pathname: string;
  filename: string;
};
export function DownloadFile({
  siren,
  millesime,
  pathname,
  filename,
}: DownloadFileProps) {
  const { hasAllowedCognitoRole } = useIsAllowed();
  const isAdmin = hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA);
  const download = () => {
    getDownloadedFile(siren, millesime, pathname, filename, isAdmin)
      .then((file) => {
        if (!file) return;
        downloadFile(file.file, file.filename);
      })
      .catch(
        handleGatewayError({
          onUnhandled: (e) => {
            console.error(e);
            displayErrorToast(
              "Une erreur est survenue lors du téléchargement du fichier"
            );
          },
        })
      );
  };

  return <Download onClick={download} className={styles.download} />;
}

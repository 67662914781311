import {
  GatewayEntreprise,
  GatewayFormationContinue,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  InputButtonGroup,
  InputDate,
  InputNumber,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormGrid,
  FormNotice,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { isBeforeToday, wasLessThanOneYearAgo } from "@src/helper/date.helper";
import { isIas, isIobsp } from "@src/helper/entreprise.helper";
import { useAssujetti } from "@src/store/store.assujetti";
import { isToday } from "date-fns";
import { isNil } from "lodash";
import { AssujettiConformiteData } from "../../../AssujettiParcoursConformite";
import { NextVerificatioCard } from "../../../Components/NextVerificationCard/NextVerificationCard";
import styles from "./ConformiteFormFormationIAS.module.scss";

export const formationContinueIasSchema = zod.object({
  id: zod.string().optional(),
  dateDernierVerification: zod
    .date()
    .refine((value) => {
      if (!value) return false;
      return wasLessThanOneYearAgo(value);
    }, "La dernière vérification date d'il y a plus d'un an. Elle n'est donc pas valable. Merci de vérifier à nouveau")
    .refine((value) => {
      if (!value) return false;
      return isBeforeToday(value) || isToday(value);
    }, "La date doit être inférieure ou égale à la date du jour."),
  soumisFormationContinue: zod.boolean(),
  formations: zod.array(
    zod.object({
      theme: zod.string(),
      nombreHeures: zod.number({
        required_error: "Champs requis",
      }),
    })
  ),
});
const getDefaultValueSoumisFormationContinue = (
  formData: GatewayFormationContinue.FormationContinueIas | null,
  isOnlyIAS: boolean
) => {
  if (isOnlyIAS) return true;
  if (!isNil(formData?.soumisFormationContinue))
    return formData?.soumisFormationContinue;
  return null;
};
export type FormationContinueIasSchemaFormValues = zod.infer<
  typeof formationContinueIasSchema
>;
export const useFormationContinueIasForm = (
  formData: GatewayFormationContinue.FormationContinueIas | null,
  categories: GatewayInfoSocieteOrias.Categorie[],
  categoriesAccessoires: GatewayInfoSocieteOrias.Categorie[]
) => {
  const categoriesWithAccessoires = [...categories, ...categoriesAccessoires];

  const isOnlyIAS =
    isIas(categoriesWithAccessoires) &&
    !isIobsp(categoriesWithAccessoires) &&
    categories.length > 0; // A au moins 1 categorie non accessoire;

  const formConfig = useFormConfig<
    typeof formationContinueIasSchema,
    FormationContinueIasSchemaFormValues
  >({
    schema: formationContinueIasSchema,
    defaultValues: {
      id: formData?.id,
      dateDernierVerification: formData?.dateDernierVerification ?? new Date(),
      soumisFormationContinue: getDefaultValueSoumisFormationContinue(
        formData,
        isOnlyIAS
      ),
      formations:
        formData?.formations && formData?.formations?.length > 0
          ? formData?.formations
          : [{ theme: null, nombreHeures: null }],
    } as FormationContinueIasSchemaFormValues,
  });

  const { fields, append, remove } =
    useFieldArray<FormationContinueIasSchemaFormValues>({
      control: formConfig.control,
      name: "formations",
    });

  const [lastVerifiedAt, soumisFormationContinue, formations] =
    formConfig.watch([
      "dateDernierVerification",
      "soumisFormationContinue",
      "formations",
    ]);

  const totalHours = formations.reduce(
    (total, formation) => total + formation.nombreHeures || 0,
    0
  );

  return {
    formConfig,
    fields,
    append,
    remove,
    totalHours,
    lastVerifiedAt,
    soumisFormationContinue,
    isOnlyIAS,
  };
};
export const useFormationContinueIas = (
  formData: GatewayFormationContinue.FormationContinueIas | null,
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType | undefined,
  data: AssujettiConformiteData,
  setFormData: (data: GatewayFormationContinue.FormationContinueIas) => void
) => {
  const {
    formConfig,
    fields,
    append,
    remove,
    totalHours,
    lastVerifiedAt,
    soumisFormationContinue,
    isOnlyIAS,
  } = useFormationContinueIasForm(
    formData,
    entreprise?.categoriesActivites ?? [],
    entreprise?.categoriesActivitesAccessoires ?? []
  );

  const { updateAvancement } = useAssujetti();
  const onSubmit = async (values: FormationContinueIasSchemaFormValues) => {
    const valuesToSubmit: GatewayFormationContinue.FormationContinueIas = {
      dateDernierVerification: values.dateDernierVerification ?? null,
      soumisFormationContinue: values.soumisFormationContinue ?? null,
      formations: values.formations.map((formation) => ({
        nombreHeures: formation.nombreHeures ?? null,
        theme: formation.theme ?? null,
      })),
      id: values.id,
    };
    const avancement = await GatewayFormationContinueApi.saveIAS(
      data.assujetti.id,
      data.assujetti.millesime,
      valuesToSubmit
    );
    updateAvancement(data.assujetti.millesime, data.assujetti.id, avancement);
    setFormData(valuesToSubmit);
  };

  return {
    formConfig,
    fields,
    append,
    remove,
    onSubmit,
    totalHours,
    lastVerifiedAt,
    soumisFormationContinue,
    isOnlyIAS,
  };
};

type ConformiteFormFormationIasContent = {
  hideTitle?: boolean;
  data: AssujettiConformiteData;
  totalHours: number;
  lastVerifiedAt: Date;
  fields: FieldArrayWithId<
    FormationContinueIasSchemaFormValues,
    "formations"
  >[];
  append: UseFieldArrayAppend<
    FormationContinueIasSchemaFormValues,
    "formations"
  >;
  remove: UseFieldArrayRemove;
  soumisFormationContinue: boolean;
  isOnlyIAS: boolean;
};
export function ConformiteFormFormationIasContent({
  hideTitle,
  data,
  totalHours,
  append,
  remove,
  lastVerifiedAt,
  fields,
  soumisFormationContinue,
  isOnlyIAS,
}: ConformiteFormFormationIasContent) {
  const name = ` - ${data.assujetti.surname} ${data.assujetti.name}`;
  const { millesime } = data.assujetti;

  return (
    <FormGrid disableHorizontalGutter>
      {!hideTitle && (
        <FormTitle className={styles.header}>
          <h2 className={styles.title}>
            <b>Formation continue IAS</b>
            {name}
          </h2>
          <IconStatus
            isValid={data.assujetti.avancement.formationContinueIas === 100}
          />
        </FormTitle>
      )}
      <FormNotice>
        <FormRow>
          <InfoCard type="info" className={hideTitle ? styles.notice : ""}>
            La personne doit avoir suivi une formation de 15h minimum en{" "}
            {millesime - 1}
          </InfoCard>
        </FormRow>
      </FormNotice>
      {!isOnlyIAS && (
        <FormRow>
          <FormField>
            <InputButtonGroup<
              FormationContinueIasSchemaFormValues,
              false,
              boolean
            >
              name="soumisFormationContinue"
              label="Est-elle soumise à la formation continue ?"
              options={[
                {
                  label: "Oui",
                  value: true,
                },
                {
                  label: "Non",
                  value: false,
                },
              ]}
            />
          </FormField>
        </FormRow>
      )}
      {soumisFormationContinue && (
        <>
          <FormRow>
            <FormField>
              <InputDate<FormationContinueIasSchemaFormValues>
                name="dateDernierVerification"
                label="Date de saisie"
              />
            </FormField>
          </FormRow>
          {fields.map(({ id }, index) => (
            <FormRow key={id}>
              <FormField size="half" takeFullSizeMobile={false}>
                <InputText name={`formations.${index}.theme`} label="Thème" />
              </FormField>
              <FormField
                size="half"
                takeFullSizeMobile={false}
                className={styles.hoursField}
              >
                <InputNumber
                  name={`formations.${index}.nombreHeures`}
                  label="Nombre d'heures"
                />
                {fields.length > 1 && (
                  <Button
                    type="button"
                    onClick={() => remove(index)}
                    className={styles.trashButton}
                    variant="text"
                    iconVariant="left"
                    icon={<TrashIcon />}
                  />
                )}
              </FormField>
            </FormRow>
          ))}
          {totalHours < 15 && (
            <FormRow>
              <FormField>
                <InfoCard type="warning">
                  Le total des heures de formation est insuffisant.
                </InfoCard>
              </FormField>
            </FormRow>
          )}
          <FormRow>
            <FormField>
              <Button
                variant="text"
                icon={<PlusCircle />}
                iconVariant="left"
                onClick={() =>
                  append({
                    nombreHeures: null,
                    theme: null,
                  } as unknown as FormationContinueIasSchemaFormValues["formations"][0])
                }
              >
                Ajouter une formation supplémentaire
              </Button>
            </FormField>
          </FormRow>
        </>
      )}
      <NextVerificatioCard lastVerifiedAt={lastVerifiedAt} />{" "}
    </FormGrid>
  );
}

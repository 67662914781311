import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { intersection } from "lodash";

export const activitesFromEntreprise = (entreprise: {
  categoriesActivites?: GatewayInfoSocieteOrias.Categorie[];
  categoriesActivitesAccessoires?: GatewayInfoSocieteOrias.Categorie[];
}): GatewayInfoSocieteOrias.Categorie[] =>
  (entreprise.categoriesActivites ?? []).concat(
    entreprise.categoriesActivitesAccessoires ?? []
  );

export const isIas = (categories: GatewayInfoSocieteOrias.Categorie[]) =>
  intersection(GatewayInfoSocieteOrias.CategorieIASList, categories).length > 0;

export const isIobsp = (categories: GatewayInfoSocieteOrias.Categorie[]) =>
  intersection(GatewayInfoSocieteOrias.CategorieIOBSPList, categories).length >
  0;

export const isMulti = (categories: GatewayInfoSocieteOrias.Categorie[]) =>
  isIas(categories) && isIobsp(categories);

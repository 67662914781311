import {
  GatewayContact,
  GatewayDeclarationActivite,
  GatewayEntreprise,
} from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayDeclarationActiviteApi } from "@src/api/conformite/declarationActivite.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { isIas, isIobsp } from "@src/helper/entreprise.helper";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";

type CopyDeclarationActiviteButtonProps = {
  declarationActivite:
    | GatewayDeclarationActivite.DeclarationActiviteIobsp
    | GatewayDeclarationActivite.DeclarationActiviteIas
    | null;
  isConforme: boolean;
};

export function CopyDeclarationActiviteButton({
  declarationActivite,
  isConforme,
}: CopyDeclarationActiviteButtonProps) {
  const { entreprisesBySiren, updateAvancement } = useEntreprises();
  const { me } = useMe();
  const typeAdhesion = me?.entreprisePrincipale.typeAdhesion;
  const { hasAllowedRole } = useIsAllowed();
  const handleCopy = async () => {
    const sirens = Object.keys(entreprisesBySiren ?? {}).filter(
      (s) => s !== declarationActivite?.siren
    );
    try {
      const payload:
        | Omit<
            GatewayDeclarationActivite.CopyDeclarationActiviteIobsp,
            "activitePrincipale" | "activites10Pourcent" | "sirens"
          >
        | Omit<
            GatewayDeclarationActivite.CopyDeclarationActiviteIas,
            "activitePrincipale" | "activites10Pourcent" | "sirens"
          > = {
        ...declarationActivite,
        siren: declarationActivite?.siren ?? "",
        millesime: declarationActivite?.millesime ?? 0,
        organisations: declarationActivite?.organisations ?? [],
        effectifGlobalEntreprise:
          declarationActivite?.effectifGlobalEntreprise ?? null,
        pourcentageCaClientsParticuliers:
          declarationActivite?.pourcentageCaClientsParticuliers ?? null,
        pourcentageCaClientsProfessionnels:
          declarationActivite?.pourcentageCaClientsProfessionnels ?? null,
        pourcentageTnsTpe: declarationActivite?.pourcentageTnsTpe ?? null,
        fournisseurs: declarationActivite?.fournisseurs ?? [],
      };

      if (
        declarationActivite?.activitePrincipale &&
        Object.keys(GatewayDeclarationActivite.ActiviteIas).includes(
          declarationActivite?.activitePrincipale
        )
      ) {
        const avancements = await GatewayDeclarationActiviteApi.copyIas({
          ...payload,
          activitePrincipale: declarationActivite.activitePrincipale ?? null,
          activites10Pourcent: declarationActivite.activites10Pourcent ?? [],
          sirens: sirens.filter((siren) => {
            const entreprise = entreprisesBySiren && entreprisesBySiren[siren];
            const categories = entreprise?.categoriesActivites ?? [];
            const categoriesAccessoires =
              entreprise?.categoriesActivitesAccessoires ?? [];

            return (
              siren !== declarationActivite?.siren &&
              isIas([...categories, ...categoriesAccessoires])
            );
          }),
        } as GatewayDeclarationActivite.CopyDeclarationActiviteIas);
        avancements.map((avancement) => {
          return updateAvancement(avancement);
        });
        displaySucessToast("Déclaration Activité IAS copiée avec succès");
      }
      if (
        declarationActivite?.activitePrincipale &&
        Object.keys(GatewayDeclarationActivite.ActiviteIobsp).includes(
          declarationActivite?.activitePrincipale
        )
      ) {
        const avancements = await GatewayDeclarationActiviteApi.copyIobsp({
          ...payload,
          activitePrincipale: declarationActivite.activitePrincipale ?? null,
          activites10Pourcent: declarationActivite.activites10Pourcent ?? [],
          sirens: sirens.filter((siren) => {
            const entreprise = entreprisesBySiren && entreprisesBySiren[siren];
            const categories = entreprise?.categoriesActivites ?? [];
            const categoriesAccessoires =
              entreprise?.categoriesActivitesAccessoires ?? [];

            return (
              siren !== declarationActivite?.siren &&
              isIobsp([...categories, ...categoriesAccessoires])
            );
          }),
        } as GatewayDeclarationActivite.CopyDeclarationActiviteIobsp);
        avancements.map((avancement) => {
          return updateAvancement(avancement);
        });
        displaySucessToast("Déclaration Activité IOBSP copiée avec succès");
      }
    } catch (err) {
      handleGatewayError({
        onUnhandled: () => {
          displayErrorToast(
            "Une erreur est survenue lors de la récupération du formulaire"
          );
        },
      })(err);
    }
  };

  if (
    isConforme &&
    hasAllowedRole(GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR) &&
    (typeAdhesion === GatewayEntreprise.TypeAdhesion.GROUPE ||
      typeAdhesion === GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES)
  )
    return (
      <Button
        variant="outline-accent"
        onClick={() => {
          handleCopy().catch(console.error);
        }}
      >
        Copier l&apos;axe pour les autres sociétés
      </Button>
    );

  return null;
}

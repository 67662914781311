import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputMask,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReSendConfirmationCodeNotice } from "@src/adhesion/Steps/AdhesionConfirm/ReSendConfirmationCodeNotice";

const schema = zod.object({
  confirmCode: zod.string().nonempty(),
});

export type SignupConfirmFormValues = zod.infer<typeof schema>;

export type SignupConfirmFormProps = {
  onSubmit: (values: SignupConfirmFormValues) => unknown;
  formId: string;
  email: string;
};

export function SignupConfirmForm({
  onSubmit,
  formId,
  email,
}: SignupConfirmFormProps) {
  const formConfig = useFormConfig<typeof schema, SignupConfirmFormValues>({
    schema,
  });

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <ReSendConfirmationCodeNotice email={email} />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField size="half">
          <InputMask<SignupConfirmFormValues>
            mask="999999"
            name="confirmCode"
            label="Code reçu par email"
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}

import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { userSession } from "@src/session/UserSession";
import { AxiosResponse } from "axios";

const defaultOptions = {
  type: "application/pdf",
};
export const downloadFile = (
  file: ArrayBuffer | Blob,
  filename: string,
  options: BlobPropertyBag = defaultOptions
) => {
  const fileToDownload = new Blob([file], options);
  const fileUrl = URL.createObjectURL(fileToDownload);

  const linkElement = document.createElement("a");
  if (filename) linkElement.download = filename;
  linkElement.href = fileUrl;
  linkElement.click();
  linkElement.remove();
};

export type DownloadedFile = { file: ArrayBuffer; filename: string };
const handleDownloadedFile = async (
  call: () => Promise<AxiosResponse<ArrayBuffer>>,
  filename: string
): Promise<DownloadedFile | null> => {
  try {
    const response = await call();
    return {
      file: response.data,
      filename,
    };
  } catch (error) {
    handleGatewayError({
      onResponse: (e) => {
        if (e.response.status === 403) {
          displayErrorToast("Vous n'avez pas accès à ce fichier");
        }
        displayErrorToast(
          "Une erreur est survenue lors du téléchargement du fichier"
        );
      },
      onUnhandled: () => {
        displayErrorToast(
          "Une erreur est survenue lors du téléchargement du fichier"
        );
      },
    })(error);
    return null;
  }
};

export const getDownloadedFile = async (
  siren: string,
  millesime: number,
  pathname: string,
  filename: string,
  idAdmin = false
): Promise<DownloadedFile | null> => {
  return handleDownloadedFile(
    () =>
      userSession.axiosInstance.get<ArrayBuffer>(
        `/documents/${siren}${
          idAdmin ? "" : `/user/${millesime}`
        }?pathname=${encodeURIComponent(pathname)}`,
        {
          responseType: "blob",
        }
      ),
    filename
  );
};
export const getDownloadedFileAdmin = async (
  siren: string,
  pathname: string,
  filename: string
): Promise<DownloadedFile | null> => {
  return handleDownloadedFile(
    () =>
      userSession.axiosInstance.get<ArrayBuffer>(
        `/documents/${siren}?pathname=${encodeURIComponent(pathname)}`,
        {
          responseType: "blob",
        }
      ),
    filename
  );
};

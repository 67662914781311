import {
  GatewayAssujetti,
  GatewayEntreprise,
  GatewayFormationContinue,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  Card,
  FormTitle,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import {
  ConformiteFormFormationIasContent,
  useFormationContinueIas,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsFormation/ConformiteFormFormationIAS/ConformiteFormFormationIasContent";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { useOnce } from "@src/hooks/useOnce";
import { useAssujetti } from "@src/store/store.assujetti";
import { useEntreprises } from "@src/store/store.entreprise";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styles from "../assujettis/AssujettiForm.module.scss";

type ConformiteFormationContinueContentProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
  fromAxe: string | null;
  formationContinue: GatewayFormationContinue.FormationContinueIas | null;
};

function ConformiteFormationContinueContent({
  assujetti,
  fromAxe,
  entreprise,
  formationContinue,
}: ConformiteFormationContinueContentProps) {
  const {
    lastVerifiedAt,
    onSubmit,
    formConfig,
    totalHours,
    fields,
    remove,
    append,
    soumisFormationContinue,
    isOnlyIAS,
  } = useFormationContinueIas(
    formationContinue,
    entreprise,
    { assujetti },
    () => {
      displaySucessToast("La conformité a bien été mise à jour");
    }
  );
  useOnce(() => {
    if (formationContinue !== null) formConfig.trigger().catch(console.error);
  });

  return (
    <MainContentPageTemplate
      title={
        <FormTitle className={styles.header}>
          <h1 className={styles.title}>
            <b>Formation Continue IAS</b> - {assujetti.surname} {assujetti.name}
          </h1>
          <IconStatus
            isValid={assujetti.avancement.formationContinueIas === 100}
          />
        </FormTitle>
      }
      actionNavigation={
        <Link
          to={`/conformite/formation-continue/ias/${entreprise.siren}${
            fromAxe === null ? "" : "?fromAxe"
          }`}
        >
          <Button variant="text" icon={<ChevronLeft />}>
            Retour
          </Button>
        </Link>
      }
    >
      <Card>
        <Form formConfig={formConfig} onSubmit={onSubmit}>
          <ConformiteFormFormationIasContent
            hideTitle
            data={{ assujetti }}
            lastVerifiedAt={lastVerifiedAt}
            totalHours={totalHours}
            fields={fields}
            remove={remove}
            append={append}
            soumisFormationContinue={soumisFormationContinue}
            isOnlyIAS={isOnlyIAS}
          />
          <div className={styles.action}>
            <Button
              onClick={() => {
                // Submitting outside of the form to avoid the form validation
                onSubmit(formConfig.getValues()).catch((e) => {
                  console.error(e);
                });
              }}
            >
              Sauvegarder
            </Button>
          </div>
        </Form>
      </Card>
    </MainContentPageTemplate>
  );
}

export function ConformiteFormationContinueIas() {
  const { entreprisesBySiren } = useEntreprises();
  const { assujettis } = useAssujetti();
  const { siren, id: idAssujetti } = useParams();
  const [query] = useSearchParams();
  const [formationContinue, setFormationContinue] =
    useState<GatewayFormationContinue.FormationContinueIas | null>();

  let entreprise: GatewayEntreprise.EntrepriseAControllerResponseType | null =
    null;
  if (siren && entreprisesBySiren?.[siren]) {
    entreprise = entreprisesBySiren[siren];
  }
  let assujetti: GatewayAssujetti.AssujettiWithAvancement | null = null;
  if (idAssujetti && assujettis?.[idAssujetti]) {
    assujetti = assujettis[idAssujetti];
  }

  const fromAxe = query.get("fromAxe");

  useEffect(() => {
    if (!assujetti) return;
    GatewayFormationContinueApi.getIAS(assujetti.id, assujetti.millesime)
      .then(setFormationContinue)
      .catch(
        handleGatewayError({
          onUnhandled: (e) => {
            console.error(e);
            displayErrorToast(
              "Une erreur est survenue lors de la récupération des données de formation continue"
            );
          },
        })
      );
  }, [assujetti]);

  if (!entreprise || !assujetti || formationContinue === undefined) return null;
  return (
    <ConformiteFormationContinueContent
      entreprise={entreprise}
      assujetti={assujetti}
      fromAxe={fromAxe}
      formationContinue={formationContinue}
    />
  );
}

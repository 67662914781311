import {
  GatewayAssujetti,
  GatewayControleQuinquennal,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { GatewayImportExportAssujettiAPI } from "@src/api/importExportAssujetti.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  assujettiExcelExportTemplate,
  assujettiExcelTemplate,
} from "@src/assujettis/ImportExport/AssujettiExcelTemplate";
import { transformAPIRowsForToRows } from "@src/assujettis/ImportExport/Export/ExportAssujettiExcelMapping";
import { getAssujettiAvancementFromCategories } from "@src/assujettis/useAssujettiAvancement";
import { LoaderBar } from "@src/components/LoaderBar/LoaderBar";
import { SidePane } from "@src/components/SidePane/SidePane";
import { isIas, isIobsp, isMulti } from "@src/helper/entreprise.helper";
import {
  addAssujettiDataValidationToSheet,
  downloadSheetFromWorkbook,
  importWorkBookFromFile,
  insertDataInSheet,
} from "@src/helper/excel/excel.export.helper";
import { format } from "date-fns";
import { uniq } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ControleQuinquennalAxeProps } from "../ControleQuinquennalAxe.type";
import { ControleQuinquennalAssujettiSidePane } from "./ControleQuinquennalAssujettiSidePane";
import { ControleQuinquennalAssujettiTable } from "./ControleQuinquennalAssujettiTable";
import styles from "./ControleQuinquennalAxeAssujettis.module.scss";

export type AssujettiFormType =
  | "honorabilite"
  | "capaciteProfessionnelleIas"
  | "capaciteProfessionnelleIobsp"
  | "formationContinueIas"
  | "formationContinueIobsp";
export const assujettisFormTypeToLabel: Record<AssujettiFormType, string> = {
  honorabilite: "Honorabilité",
  capaciteProfessionnelleIas: "Capacité pro IAS",
  capaciteProfessionnelleIobsp: "Capacité pro IOBSP",
  formationContinueIas: "Formation IAS",
  formationContinueIobsp: "Formation IOBSP",
};

const getActivitesFromControleQuinquennalAxes = (
  axes: GatewayControleQuinquennal.ControleQuinquennalAxe[]
): GatewayInfoSocieteOrias.Categorie[] => {
  const categories = axes.reduce<GatewayInfoSocieteOrias.Categorie[]>(
    (acc, axe) => {
      switch (axe.type) {
        case GatewayControleQuinquennal.ControleQuinquennalAxeType.RCPRO_COA:
          acc.push(GatewayInfoSocieteOrias.Categorie.COA);
          break;
        case GatewayControleQuinquennal.ControleQuinquennalAxeType.RCPRO_MIA:
          acc.push(GatewayInfoSocieteOrias.Categorie.MIA);
          break;
        case GatewayControleQuinquennal.ControleQuinquennalAxeType.RCPRO_COBSP:
          acc.push(GatewayInfoSocieteOrias.Categorie.COBSP);
          break;
        case GatewayControleQuinquennal.ControleQuinquennalAxeType.RCPRO_MIOBSP:
          acc.push(GatewayInfoSocieteOrias.Categorie.MIOBSP);
          break;
        default:
      }
      return acc;
    },
    []
  );
  return uniq(categories);
};
const getAssujettisAxesFromCategories = (
  categories: GatewayInfoSocieteOrias.Categorie[]
): AssujettiFormType[] =>
  (
    [
      "honorabilite",
      isIas(categories)
        ? (["formationContinueIas", "capaciteProfessionnelleIas"] as const)
        : [],
      isIobsp(categories)
        ? (["formationContinueIobsp", "capaciteProfessionnelleIobsp"] as const)
        : [],
    ] as const
  ).flat();

const FILE_PATH_EXCEL_ASSUJETTIS = "modele_personnes_assujetties.xlsx";

export function ControleQuinquennalAxeAssujettis({
  controleQuinquennal,
  axe,
  entreprise,
  setIsValid,
}: ControleQuinquennalAxeProps) {
  const [selectedAssujetti, setSelectedAssujetti] =
    useState<GatewayAssujetti.AssujettiWithAvancement>();
  const [assujettis, setAssujettis] =
    useState<GatewayAssujetti.ListAssujettiWithAvancementResponseType>([]);
  const categories = getActivitesFromControleQuinquennalAxes(
    controleQuinquennal.controleQuinquennalAxes
  );

  const getAssujetisAvancement =
    getAssujettiAvancementFromCategories(categories);
  const assujettiWithAvancement = assujettis.map((assujetti) => ({
    assujetti,
    avancement: getAssujetisAvancement(assujetti),
  }));
  const numberOfAssujettisConforme = assujettiWithAvancement.filter(
    (assujetti) => assujetti.avancement === 100
  ).length;
  useEffect(() => {
    GatewayAssujettiApi.listAdmin(
      controleQuinquennal.millesime,
      entreprise.siren
    )
      .then((res) => setAssujettis(res))
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération des assujettis"
            );
          },
        })(error);
      });
  }, [axe, controleQuinquennal, entreprise.siren]);

  useEffect(() => {
    setIsValid(numberOfAssujettisConforme === assujettis.length);
  }, [numberOfAssujettisConforme, assujettis, setIsValid]);

  const getDownloadedFileName = useCallback(() => {
    return `export_p_assujetties_${format(
      new Date(),
      "dd-MM-yyyy_HH:mm"
    )}.xlsx`;
  }, []);

  const exportAssujettis = async () => {
    const workbook = await importWorkBookFromFile(FILE_PATH_EXCEL_ASSUJETTIS);
    const sheet = workbook.worksheets[0];
    const exportData =
      await GatewayImportExportAssujettiAPI.adminExportAssujetti(
        controleQuinquennal.idEntreprise,
        controleQuinquennal.millesime
      );
    insertDataInSheet(
      exportData.map(transformAPIRowsForToRows),
      sheet,
      assujettiExcelTemplate,
      assujettiExcelExportTemplate
    );
    addAssujettiDataValidationToSheet(sheet, exportData.length);
    await downloadSheetFromWorkbook(getDownloadedFileName(), workbook);
  };
  const axes = getAssujettisAxesFromCategories(categories);
  return (
    <div>
      <div className={styles.header}>
        <h3>Personnes assujetties</h3>
        <LoaderBar
          progress={numberOfAssujettisConforme}
          total={assujettis.length}
        />
        <p>
          {numberOfAssujettisConforme}/{assujettis.length} validées
        </p>
        <Button
          variant="text"
          onClick={() => {
            exportAssujettis().finally(() => undefined);
          }}
        >
          Télécharger en excel
        </Button>
      </div>
      <ControleQuinquennalAssujettiTable
        assujettiWithAvancement={assujettiWithAvancement}
        setSelectedAssujetti={setSelectedAssujetti}
        axes={axes}
      />
      <SidePane
        isOpen={!!selectedAssujetti}
        setIsOpen={(isOpen) => {
          if (!isOpen) setSelectedAssujetti(undefined);
        }}
        hideReopenButton
      >
        {() => (
          <ControleQuinquennalAssujettiSidePane
            assujetti={selectedAssujetti}
            axes={axes}
            isMonoActivite={!isMulti(categories)}
            categories={categories}
            categoriesAccessoires={categories}
          />
        )}
      </SidePane>
    </div>
  );
}

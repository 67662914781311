import { GatewayEntreprise } from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useImportSocietes } from "@src/adhesion/Steps/AdhesionModeImport/useImportSocietes";
import { useDialog } from "@src/components/Dialog/useDialog";
import { RenouvellementStepEnum } from "@src/renouvellement/ParcoursRenouvellement.definition";
import { useEntreprises } from "@src/store/store.entreprise";
import { differenceBy } from "lodash";
import { useRef } from "react";
import { RenouvellementData } from "../../RenouvellementData";
import { transformImportSocietesDataToRenouvellementData } from "../list/RenouvellementMajSocietesList";
import { RenouvellementMajSocietesDialog } from "./dialog/RenouvellementMajSocietesDialog";

export type SelectedModeImport = "complete" | "replace";

const formatPreviousEntreprisesToImport = (
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
) => {
  return {
    index: -1,
    societe: {
      siren: entreprise.siren,
      numeroOrias: entreprise.numeroOrias,
      formeJuridique: entreprise.formeJuridique,
      raisonSociale: entreprise.raisonSociale,
      typeVoie: entreprise.adresse.typeVoie,
      libelleVoie: entreprise.adresse.libelleVoie,
      numeroVoie: entreprise.adresse.numeroVoie,
      complementAdresse: entreprise.adresse.complementAdresse,
      codePostal: entreprise.adresse.codePostal,
      ville: entreprise.adresse.ville,
      pays: entreprise.adresse.pays,
      trancheEffectif: entreprise.trancheEffectif,
      chiffreAffaire: entreprise.chiffreAffaire,
      categoriesOrias: entreprise.categoriesActivites,
      coaAccessoire:
        entreprise.categoriesActivitesAccessoires.find(
          (activite) => activite === "COA"
        ) !== undefined,
      miaAccessoire:
        entreprise.categoriesActivitesAccessoires.find(
          (activite) => activite === "MIA"
        ) !== undefined,
      cobspAccessoire:
        entreprise.categoriesActivitesAccessoires.find(
          (activite) => activite === "COBSP"
        ) !== undefined,
      miobspAccessoire:
        entreprise.categoriesActivitesAccessoires.find(
          (activite) => activite === "MIOBSP"
        ) !== undefined,
      sousCategorieCobsp: entreprise.sousCategorieCOBSP,
      sousCategorieMiobsp: entreprise.sousCategoriesIOBSP,
      organisationPro: entreprise.organisationProfessionnelle,
      categorieRepresentation: entreprise.categorieRepresentation,
    },
  };
};

export const useImportRenouvellement = (
  data: RenouvellementData,
  addData: (nextData: Partial<RenouvellementData>) => void,
  changeStep: (nextStep: RenouvellementStepEnum) => void
) => {
  const { entreprisesBySiren } = useEntreprises();

  const { openDialog } = useDialog();
  const selectedMode = useRef<SelectedModeImport | null>(null);
  const { handleImportSocietes, status } = useImportSocietes(
    async (importedInFile) => {
      try {
        const shouldContinue = await openDialog(({ onClose, dialogRef }) => (
          <RenouvellementMajSocietesDialog
            onClose={onClose}
            dialogRef={dialogRef}
            setSelectedMode={(nextSelectedMode) => {
              selectedMode.current = nextSelectedMode;
            }}
          />
        ));
        if (!shouldContinue || !selectedMode.current) return;
        const { societesDoublons, societesToAdd, societesInError } =
          importedInFile;
        let nextData: Partial<RenouvellementData> = {
          entreprisesUpdateCount: data.entreprisesUpdateCount + 1,
          societesDoublons,
          societesInError,
        };
        if (selectedMode.current === "replace") {
          nextData = Object.assign(nextData, {
            entreprisesAControler:
              transformImportSocietesDataToRenouvellementData(societesToAdd),
            societesToAdd,
          });
        } else if (selectedMode.current === "complete") {
          const notInFile = differenceBy(
            Object.values(entreprisesBySiren ?? {}),
            [...societesToAdd, ...societesInError].map(
              ({ societe }) => societe
            ),
            "siren"
          );
          const entreprisesToAdd = [
            ...notInFile.map(formatPreviousEntreprisesToImport),
            ...societesToAdd,
          ];
          nextData = Object.assign(nextData, {
            entreprisesAControler:
              transformImportSocietesDataToRenouvellementData(entreprisesToAdd),
            societesToAdd: entreprisesToAdd,
          });
        }
        addData(nextData);
        changeStep(RenouvellementStepEnum.LISTE_SOCIETES_IMPORT);
      } catch (err) {
        console.error(err);
        displayErrorToast("Une erreur inconnue est survenue");
      }
    }
  );

  return { handleImportSocietes, status };
};

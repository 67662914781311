import {
  Button,
  Card,
  displayInfoToast,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import { ExportEntrepriseButton } from "@src/societes/export/ExportEntrepriseButton";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMemo } from "react";
import style from "./RenouvellementMajSocietesIntro.module.scss";
import { useImportRenouvellement } from "./useImportRenouvellement";

export function RenouvellementMajSocietesIntro({
  changeStep,
  addData,
  data,
}: ParcoursRenouvellementStepComponentProps) {
  const { entreprisesBySiren } = useEntreprises();

  const entreprisesToDownload = useMemo(
    () => (entreprisesBySiren ? Object.values(entreprisesBySiren) : []),
    [entreprisesBySiren]
  );

  const { handleImportSocietes, status } = useImportRenouvellement(
    data,
    addData,
    changeStep
  );

  return (
    <ParcoursFullPageTemplate
      size="large"
      title="Mise à jour des sociétés à contrôler"
    >
      <FormRow>
        <FormField>
          <InfoCard type="info">
            N&apos;oubliez pas d&apos;inclure la société qui administre le
            groupe si elle est également soumise au contrôle
          </InfoCard>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={style.cards}>
          <Card className={style.card}>
            <h3>Importer en masse la liste à jour des sociétés</h3>
            <p>
              Renseignez toutes les informations des sociétés dans un fichier
              excel à partir de la liste actuelle de vos sociétés.
            </p>
            <ExportEntrepriseButton
              variant="text"
              icon={<Download />}
              className={style.download}
              entreprises={entreprisesToDownload}
            >
              Télécharger la liste des sociétés connues
            </ExportEntrepriseButton>
            <FileInputButton
              disabled={status === "loading"}
              icon={status === "loading" ? <Loader /> : undefined}
              onChange={(e) => {
                displayInfoToast("Import des sociétés en cours");
                handleImportSocietes(e);
              }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              Importer le fichier mis à jour
            </FileInputButton>
          </Card>
          <Card className={style.card}>
            <h3>Ou modifier les sociétés individuellement</h3>
            <p>
              Saisissez directement les modifications dans l&apos;outil, ligne
              par ligne.
            </p>
            <Button
              variant="outline"
              onClick={() => changeStep(RenouvellementStepEnum.LISTE_SOCIETES)}
            >
              Accéder à la liste
            </Button>
          </Card>
        </FormField>
      </FormRow>
    </ParcoursFullPageTemplate>
  );
}

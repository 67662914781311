import {
  GatewayFormationContinue,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  Button,
  FormAction,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { ReactComponent as ChevronLeftIcon } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as SaveIcon } from "@src/assets/icons/save.svg";
import { isMulti } from "@src/helper/entreprise.helper";
import { useEntreprises } from "@src/store/store.entreprise";
import {
  AssujettiParcoursConformiteEnum,
  ConformiteStepComponentProps,
  useChangeStepConformite,
} from "../../../AssujettiParcoursConformite";
import {
  ComponentWithFormData,
  WithFormData,
} from "../../../Components/ComponentWithFormData";
import { FormParcoursConformite } from "../../../Components/FormParcoursConformite";
import styles from "./ConformiteFormFormationIOBSP.module.scss";
import {
  ConformiteFormFormationIobspContent,
  useFormationContinueIobsp,
} from "./ConformiteFormFormationIOBSPContent";

export function ConformiteFormFormationIOBSPWithData({
  data,
  changeStep,
  closePane,
  formData,
  setFormData,
  sousActivitesFinancieres,
  entreprise,
  readonly,
}: WithFormData<
  ConformiteStepComponentProps & {
    sousActivitesFinancieres: GatewayInfoSocieteOrias.ActiviteBancaire[];
  },
  GatewayFormationContinue.FormationContinueIobsp
>) {
  const { goNext, goPrev } = useChangeStepConformite(
    AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IOBSP,
    changeStep,
    closePane,
    entreprise
  );
  const isMultiActivite = entreprise
    ? isMulti(entreprise.categoriesActivites)
    : false;

  const {
    append,
    remove,
    fields,
    lastVerifiedAt,
    formConfig,
    onSubmit,
    totalHours,
    soumisFormationContinue,
  } = useFormationContinueIobsp(
    formData,
    data,
    setFormData,
    sousActivitesFinancieres,
    !isMultiActivite
  );

  return (
    <FormParcoursConformite
      readonly={readonly}
      validateOnMount={formData !== null}
      renderActions={(submitAnyway) => (
        <FormAction className={styles.actions}>
          <Button
            variant="text"
            onClick={() => {
              submitAnyway().finally(goPrev);
            }}
            type="button"
            iconVariant="left"
            icon={<ChevronLeftIcon />}
            disabled={formConfig.formState.isSubmitting}
          >
            Précédent
          </Button>
          <div className={styles.actionsRight}>
            <Button
              variant="text"
              onClick={() => {
                submitAnyway().finally(closePane);
              }}
              type="button"
              iconVariant="left"
              icon={<SaveIcon />}
              disabled={formConfig.formState.isSubmitting}
            >
              {readonly ? "Fermer" : "Sauvegarder et fermer"}
            </Button>
            <Button
              type="button"
              onClick={() => {
                submitAnyway().finally(goNext);
              }}
              disabled={formConfig.formState.isSubmitting}
            >
              Suivant
            </Button>
          </div>
        </FormAction>
      )}
      formConfig={formConfig}
      onSubmit={onSubmit}
    >
      <ConformiteFormFormationIobspContent
        lastVerifiedAt={lastVerifiedAt}
        fields={fields}
        remove={remove}
        append={append}
        sousActivitesFinancieres={sousActivitesFinancieres}
        data={data}
        totalHours={totalHours}
        soumisFormationContinue={soumisFormationContinue}
        isMultiActivite={isMultiActivite}
      />
    </FormParcoursConformite>
  );
}

export function ConformiteFormContinueIOBSP({
  data,
  ...otherProps
}: ConformiteStepComponentProps) {
  const { entreprisesBySiren } = useEntreprises();
  const entrepriseRattachee = entreprisesBySiren
    ? entreprisesBySiren[data.assujetti.sirenCompany]
    : undefined;

  const sousActivitesFinancieres = [
    ...(entrepriseRattachee?.sousCategorieCOBSP ?? []),
    ...(entrepriseRattachee?.sousCategoriesIOBSP ?? []),
  ];

  if (!entrepriseRattachee) return <div />;

  return (
    <ComponentWithFormData
      FormComponent={ConformiteFormFormationIOBSPWithData}
      formProps={{ data, ...otherProps, sousActivitesFinancieres }}
      fetchData={async () =>
        GatewayFormationContinueApi.getIOBSP(
          data.assujetti.id,
          data.assujetti.millesime
        )
      }
    />
  );
}

import { GatewayFormationContinue } from "@conformite/gateway";
import {
  Button,
  FormAction,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { ReactComponent as ChevronLeftIcon } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as SaveIcon } from "@src/assets/icons/save.svg";
import {
  AssujettiParcoursConformiteEnum,
  ConformiteStepComponentProps,
  useChangeStepConformite,
} from "../../../AssujettiParcoursConformite";
import {
  ComponentWithFormData,
  WithFormData,
} from "../../../Components/ComponentWithFormData";
import { FormParcoursConformite } from "../../../Components/FormParcoursConformite";
import styles from "./ConformiteFormFormationIAS.module.scss";
import {
  ConformiteFormFormationIasContent,
  useFormationContinueIas,
} from "./ConformiteFormFormationIasContent";

export function ConformiteFormFormationIASWithData({
  data,
  changeStep,
  closePane,
  formData,
  setFormData,
  entreprise,
  readonly,
}: WithFormData<
  ConformiteStepComponentProps,
  GatewayFormationContinue.FormationContinueIas
>) {
  const { goNext, goPrev } = useChangeStepConformite(
    AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IAS,
    changeStep,
    closePane,
    entreprise
  );
  const {
    totalHours,
    append,
    fields,
    remove,
    onSubmit,
    formConfig,
    lastVerifiedAt,
    soumisFormationContinue,
    isOnlyIAS,
  } = useFormationContinueIas(formData, entreprise, data, setFormData);

  return (
    <FormParcoursConformite
      readonly={readonly}
      validateOnMount={formData !== null}
      renderActions={(submitAnyway) => (
        <FormAction className={styles.actions}>
          <Button
            variant="text"
            onClick={() => {
              submitAnyway().finally(goPrev);
            }}
            type="button"
            iconVariant="left"
            icon={<ChevronLeftIcon />}
            disabled={formConfig.formState.isSubmitting}
          >
            Précédent
          </Button>
          <div className={styles.actionsRight}>
            <Button
              variant="text"
              onClick={() => {
                submitAnyway().finally(closePane);
              }}
              type="button"
              iconVariant="left"
              icon={<SaveIcon />}
              disabled={formConfig.formState.isSubmitting}
            >
              {readonly ? "Fermer" : "Sauvegarder et fermer"}
            </Button>
            <Button
              type="button"
              onClick={() => {
                submitAnyway().finally(goNext);
              }}
              disabled={formConfig.formState.isSubmitting}
            >
              Suivant
            </Button>
          </div>
        </FormAction>
      )}
      formConfig={formConfig}
      onSubmit={onSubmit}
    >
      <ConformiteFormFormationIasContent
        data={data}
        remove={remove}
        append={append}
        fields={fields}
        lastVerifiedAt={lastVerifiedAt}
        totalHours={totalHours}
        soumisFormationContinue={soumisFormationContinue}
        isOnlyIAS={isOnlyIAS}
      />
    </FormParcoursConformite>
  );
}

export function ConformiteFormContinueIAS({
  data,
  ...otherProps
}: ConformiteStepComponentProps) {
  return (
    <ComponentWithFormData
      FormComponent={ConformiteFormFormationIASWithData}
      formProps={{ data, ...otherProps }}
      fetchData={async () =>
        GatewayFormationContinueApi.getIAS(
          data.assujetti.id,
          data.assujetti.millesime
        )
      }
    />
  );
}

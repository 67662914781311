import {
  GatewayAssujetti,
  GatewayFormationContinue,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import {
  ConformiteFormFormationIobspContent,
  useFormationContinueIobspForm,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsFormation/ConformiteFormFormationIOBSP/ConformiteFormFormationIOBSPContent";
import { FieldSet } from "@src/components/FieldSet/FieldSet";
import { isMulti } from "@src/helper/entreprise.helper";
import { useCallback } from "react";
import { ControleQuinquennalFormTitle } from "./ControleQuinquennalFormTitle";
import { useControleQuinquennalForm } from "./useControleQuinquennalForm";

export const useFormationContinueIobsp = (
  assujetti: GatewayAssujetti.AssujettiWithAvancement | undefined,
  needed: boolean
) => {
  const call = useCallback(() => {
    if (!assujetti) return Promise.resolve(null);
    return GatewayFormationContinueApi.getIobspAdmin(
      assujetti.id,
      assujetti.millesime
    );
  }, [assujetti]);
  const { content, hasLoaded } = useControleQuinquennalForm(
    assujetti,
    needed,
    call
  );
  return {
    formationContinueIobsp: content,
    hasLoadedFormationContinueIobsp: hasLoaded,
  };
};
type ControleQuinquennalAssujettiSidePaneContentFormationContinueIobspProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  formationContinue: GatewayFormationContinue.FormationContinueIobsp | null;
  categories: GatewayInfoSocieteOrias.Categorie[];
};

export function ControleQuinquennalAssujettiSidePaneContentFormationContinueIobsp({
  assujetti,
  formationContinue,
  categories,
}: ControleQuinquennalAssujettiSidePaneContentFormationContinueIobspProps) {
  const sousActiviteFinanciere: GatewayInfoSocieteOrias.ActiviteBancaire[] = [];
  const isMultiActivite = isMulti(categories);
  const props = useFormationContinueIobspForm(
    formationContinue,
    sousActiviteFinanciere,
    !isMultiActivite
  );
  return (
    <>
      <ControleQuinquennalFormTitle
        assujetti={assujetti}
        title="Formation Continue IOBSP"
        avancement="formationContinueIobsp"
      />
      <Form formConfig={props.formConfig} onSubmit={() => null}>
        <FieldSet disabled>
          <ConformiteFormFormationIobspContent
            {...props}
            data={{ assujetti }}
            sousActivitesFinancieres={sousActiviteFinanciere}
            hideTitle
            isMultiActivite={isMultiActivite}
          />
        </FieldSet>
      </Form>
    </>
  );
}

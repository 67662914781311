import {
  UseFormReturn,
  useFieldArray,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { FileRead } from "@src/components/FileRead/FileRead";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { millesimeHasEveryDay } from "@src/conformite/conformite.helper";
import { useMe } from "@src/store/store.me";
import { useMillesime } from "@src/store/store.millesime";
import styles from "../ConformiteRcPro.module.scss";
import { ConformiteRcProEditFileValues } from "./ConformiteRcProEditFileValues";

export type FormDataFiles = {
  files: (
    | {
        filename?: string | undefined;
        pathname?: string | undefined;
        startDate?: Date | undefined;
        endDate?: Date | undefined;
        orias?: string | undefined;
        file?: File | undefined;
        isValid: boolean;
      }
    | undefined
  )[];
};

type ConformiteRcProEditFilesProps = {
  hasRcpIndividuelleSouscritSetToNo: boolean;
  form: UseFormReturn<FormDataFiles>;
};
export function ConformiteRcProEditFiles({
  hasRcpIndividuelleSouscritSetToNo,
  form,
}: ConformiteRcProEditFilesProps) {
  const { files: filesWatched } = form.watch();
  const { selected: millesime } = useMillesime();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "files",
  });
  const { me } = useMe();
  const fileLabel = `Ajouter l'attestation de ${
    hasRcpIndividuelleSouscritSetToNo
      ? "votre mandant"
      : "responsabilité civile professionnelle"
  }`;
  const shouldShowAddFileForRcp =
    millesime &&
    (!filesWatched ||
      (filesWatched.every((file) => !!file?.startDate && !!file?.endDate) &&
        !millesimeHasEveryDay(millesime, filesWatched)));

  const shouldShowAddFile =
    hasRcpIndividuelleSouscritSetToNo || shouldShowAddFileForRcp;
  if (!me) return null;
  return (
    <>
      <h4 className={styles.subtitle}>Pièces justificatives</h4>
      {millesime === 2024 && (
        <InfoCard className={styles.cardInformationsPJ}>
          Dans le cadre du renouvellement 2024, si vous ne disposez pas encore
          de votre attestation pour l&apos;année 2024/2025, nous vous invitons à
          renseigner et enregistrer les éléments dont vous disposez à ce jour.
          Vous pourrez compléter cet axe à réception de votre attestation.
        </InfoCard>
      )}
      {millesime === 2025 && (
        <InfoCard className={styles.cardInformationsPJ}>
          <b>
            Nous vous invitons à déposer vos attestations de RC professionnelle
            permettant de couvrir l&apos;année civile en indiquant pour chaque
            pièce les dates de couverture, à savoir 01/01/2025 au 31/12/2025 (EX
            : 01/01/2025 au 28/02/2025 et 01/03/2025 au 28/02/2026).
          </b>{" "}
          Dans le cadre du renouvellement 2025 si vous ne disposez pas encore de
          votre attestation pour l&apos;année 2025/2026, nous vous invitons à
          renseigner et enregistrer les éléments dont vous disposez à ce jour et
          notamment l&apos;attestation vous couvrant jusqu&apos;au 28 février
          2025. Vous pourrez compléter cet axe à réception de votre attestation.
        </InfoCard>
      )}
      <div className={styles.filesContainer}>
        {fields.map((field, index) => (
          <div key={field.id} className={styles.fileContainer}>
            <FileRead
              name={field.filename}
              deletable
              onDelete={() => {
                remove(index);
              }}
            />
            {!field.isValid && (
              <InfoCard type="error">
                Votre document n&apos;est pas valide. Veuillez le remplacer par
                un document valide.
              </InfoCard>
            )}

            <ConformiteRcProEditFileValues
              index={index}
              isMandant={hasRcpIndividuelleSouscritSetToNo}
              form={form}
            />
          </div>
        ))}
        {shouldShowAddFile && (
          <FileInputButton
            accept="application/pdf"
            variant="input"
            onChange={(e) => {
              const file = e.target.files?.item(0);
              if (!file) return;
              append({
                filename: file.name,
                file,
                isValid: true,
              });
            }}
          >
            {fileLabel}
          </FileInputButton>
        )}
      </div>
    </>
  );
}

import {
  GatewayConformiteRcPro,
  GatewayContact,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayRcProApi } from "@src/api/conformite/rcPro.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";

type CopyRcProButtonProps = {
  isConforme: boolean;
  categorie: GatewayInfoSocieteOrias.Categorie;
  siren: string;
  millesime: number;
};

export function CopyRcProButton({
  isConforme,
  categorie,
  siren,
  millesime,
}: CopyRcProButtonProps) {
  const { entreprisesBySiren, updateAvancement } = useEntreprises();
  const { me } = useMe();
  const typeAdhesion = me?.entreprisePrincipale.typeAdhesion;
  const { hasAllowedRole } = useIsAllowed();

  const getSirenList = () => {
    const sirens = Object.keys(entreprisesBySiren ?? {}).filter(
      (s) => s !== siren
    );
    return sirens.filter((currentSiren) => {
      const entreprise = entreprisesBySiren && entreprisesBySiren[siren];
      const categories = entreprise?.categoriesActivites ?? [];
      const categoriesAccessoires =
        entreprise?.categoriesActivitesAccessoires ?? [];
      return (
        currentSiren !== siren &&
        [...categories, ...categoriesAccessoires].includes(categorie)
      );
    });
  };

  const handleCopy = async () => {
    const sirens = getSirenList();
    try {
      const avancements = await GatewayRcProApi.copyRcPro(
        sirens,
        millesime,
        siren,
        categorie.toLowerCase() as GatewayConformiteRcPro.CategorieRcPro
      );
      avancements.map((avancement) => {
        return updateAvancement(avancement);
      });
      displaySucessToast(
        `RC Pro ${categorie} copiée avec succès pour l'ensemble des mandataires`
      );
    } catch (err) {
      handleGatewayError({
        onUnhandled: () => {
          displayErrorToast(
            "Une erreur est survenue lors de la copie de l'axe"
          );
        },
      })(err);
    }
  };

  if (
    isConforme &&
    hasAllowedRole(GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR) &&
    typeAdhesion === GatewayEntreprise.TypeAdhesion.GROUPEMENT_MANDATAIRES
  )
    return (
      <Button
        variant="outline-accent"
        onClick={() => {
          handleCopy().catch(console.error);
        }}
      >
        Copier l&apos;axe pour l&apos;ensemble des mandataires
      </Button>
    );

  return null;
}
